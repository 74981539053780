import type { CellClassParams, RowClassParams } from '@ag-grid-community/core';

const BOLD_ROW_NAMES = new Set([
  'TOTAL_COSTS',
  'TOTAL_COSTS_AFTER_COST_ALLOCATION',
  'TOTAL_REVENUE',
  'TOTAL_REVENUE_AFTER_REVENUE_ALLOCATION',
  'REIMBURSEMENT_NEEDS',
]);

const getFontBoldClass = (nodeLevel: number, rowName?: string): string =>
  nodeLevel === 0 || (rowName && BOLD_ROW_NAMES.has(rowName)) ? 'font-bold' : '';

export const getFontBoldClassForLevel =
  (level: number) =>
  ({ node }: CellClassParams | RowClassParams) =>
    node.level === level ? 'font-bold' : '';

const getHighlightedClass = ({ data, node: { level } }: RowClassParams) => {
  if (data?.disableHighlight || level !== 0) {
    return '';
  }
  return 'bg-highlighted';
};

export const getExcelHighlightedClass = ({ data, node: { level } }: RowClassParams) => {
  if (data?.disableHighlight || level !== 0) {
    return '';
  }
  return 'excel-bg-highlighted';
};

const getWhiteClass = ({ data, node: { level } }: RowClassParams) => {
  if (data?.disableHighlight || level !== 0) {
    return 'bg-white';
  }
  return '';
};

const getCursorPointerClass = (nodeLevel: number, accountId?: number): string =>
  nodeLevel === 2 && accountId ? 'cursor-pointer' : '';

export const getRowClasses =
  (...extraRowClassFunctions: ((rowClassParams: RowClassParams) => string)[]) =>
  (rowClassParams: RowClassParams) => [
    getHighlightedClass(rowClassParams),
    getWhiteClass(rowClassParams),
    ...extraRowClassFunctions.map((rowClassFunction) => rowClassFunction(rowClassParams)),
  ];

export const getExcelCellClasses =
  (...extraRowClassFunctions: ((rowClassParams: RowClassParams) => string)[]) =>
  (rowClassParams: RowClassParams) => [
    getExcelHighlightedClass(rowClassParams),
    ...extraRowClassFunctions.map((rowClassFunction) => rowClassFunction(rowClassParams)),
  ];

export const getCellClasses =
  (...extraCellClassFunctions: ((cellClassParams: CellClassParams | RowClassParams) => string)[]) =>
  (cellClassParams: CellClassParams | RowClassParams) => {
    const { data, node } = cellClassParams;
    const { level: nodeLevel } = node;
    const { accountId } = data ?? {};

    if (data?.accountId === '') {
      return [getFontBoldClass(nodeLevel)];
    }

    return [
      getExcelHighlightedClass(cellClassParams),
      getFontBoldClass(nodeLevel),
      getCursorPointerClass(nodeLevel, accountId),
      ...extraCellClassFunctions.map((cellClassFunction) => cellClassFunction(cellClassParams)),
    ];
  };
