'use client';

import type { ColDef } from '@ag-grid-community/core';
import type { AgGridReact } from '@ag-grid-community/react';
import { useMemo, useRef } from 'react';

import { useTranslations } from 'next-intl';

import { aggregated } from '@org/query';
import { BaseTable } from '@org/ui';
import { getRowClasses } from '@org/utils';

const defaultColDef: ColDef = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: false,
  resizable: true,
  sortable: false,
};

interface ConfigType {
  inputName: string;
  type: string;
  description: string;
  optional: boolean;
}

const config = {} as Record<string, ConfigType[]>;
Object.entries(aggregated.FILE_CONFIG).forEach(([, value]) => {
  config[value.apiType] = Object.entries(value.fields).map(([, fields]) => fields);
});

const configNames = Object.entries(aggregated.FILE_CONFIG).map(([name]) => name);

export const HelpMenu = () => {
  const gridRef = useRef<AgGridReact>(null);

  const t = useTranslations();

  const columnsDefs = useMemo(
    () =>
      [
        {
          colId: 'inputName',
          field: 'inputName',
          headerName: t('main.helpMenu.tableColumns.inputName'),
          maxWidth: 120,
        },
        {
          colId: 'type',
          field: 'type',
          headerName: t('main.helpMenu.tableColumns.type'),
          maxWidth: 100,
        },
        {
          colId: 'description',
          field: 'description',
          flex: 1,
          autoHeight: true,
          wrapText: true,
          headerName: t('main.helpMenu.tableColumns.description'),
        },
        {
          colId: 'optional',
          field: 'optional',
          headerName: t('main.helpMenu.tableColumns.optional'),
          maxWidth: 100,
        },
      ] satisfies ColDef<ConfigType>[],
    [t],
  );

  return configNames.map((name) => (
    <div key={name}>
      <div className="pb-3">{t(`common.apiType.${name}`)}</div>
      <BaseTable
        className="!h-[380px]"
        columnDefs={columnsDefs}
        defaultColDef={defaultColDef}
        getRowClass={getRowClasses()}
        ref={gridRef}
        rowData={config[name]}
      />
    </div>
  ));
};
