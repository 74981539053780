'use client';

import { Accordion, AccordionItem } from '@nextui-org/react';
import { useTranslations } from 'next-intl';

import { aggregated } from '@org/query';
import { getStatusValue } from '@org/utils';

import { WorkflowLink } from '~/components/WorkflowLink';
import { usePathParams } from '~/hooks';
import * as urls from '~/urls';

export const WorkflowMenu = () => {
  const t = useTranslations();
  const params = usePathParams({});

  const { configsStatus } = aggregated.useConfigStatus({
    masterConfigurationId: params.masterConfigurationId,
  });

  return (
    <div className="h-full">
      <Accordion
        className="px-0"
        defaultExpandedKeys={['bab', 'interest-report', 'cost-unit', 'price-sheet']}
        isCompact
        itemClasses={{
          content: 'py-0',
          title: 'text-sm',
          trigger: 'px-2 py-3.5',
        }}
        selectionMode="multiple"
        showDivider={false}
      >
        <AccordionItem
          aria-label={t('common.enums.configItemGroups.BAB')}
          key="bab"
          title={t('common.enums.configItemGroups.BAB')}
        >
          <WorkflowLink
            href={urls.EXPENSE_REVENUE_ACCOUNTS_CONFIGURATION_PAGE(params)}
            status={getStatusValue({ configsStatus, name: 'ACCOUNT_RANGE_CONFIG' })}
          >
            {t('main.clients.menu.configMenu.expense-and-revenue-accounts')}
          </WorkflowLink>
          <WorkflowLink
            href={urls.COST_TYPE_ACCOUNTS_CONFIGURATION_PAGE(params)}
            status={getStatusValue({ configsStatus, name: 'COST_TYPE_ACCOUNT_CONFIG' })}
          >
            {t('main.clients.menu.configMenu.cost-type-accounts')}
          </WorkflowLink>
          <WorkflowLink
            href={urls.COST_CENTERS_CONFIGURATION_PAGE(params)}
            status={getStatusValue({ configsStatus, name: 'COST_CENTER_CONFIG' })}
          >
            {t('main.clients.menu.configMenu.cost-centers')}
          </WorkflowLink>
          <WorkflowLink
            href={urls.ALLOCATION_KEYS_CONFIGURATION_PAGE(params)}
            status={getStatusValue({ configsStatus, name: 'ALLOCATION_KEY_CONFIG' })}
          >
            {t('main.clients.menu.configMenu.allocation-keys')}
          </WorkflowLink>
          <WorkflowLink href={urls.IMPUTED_CONCESSION_FEE_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.imputed-concession-fee')}
          </WorkflowLink>
          <WorkflowLink href={urls.CORPORATE_RISK_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.corporate-risk')}
          </WorkflowLink>
          <WorkflowLink href={urls.IMPUTED_TRADE_TAX_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.imputed-trade-tax')}
          </WorkflowLink>
        </AccordionItem>
        <AccordionItem
          aria-label={t('common.enums.configItemGroups.INTEREST_REPORT')}
          key="interest-report"
          title={t('common.enums.configItemGroups.INTEREST_REPORT')}
        >
          <WorkflowLink
            href={urls.ASSET_COST_CENTERS_CONFIGURATION_PAGE(params)}
            status={getStatusValue({ configsStatus, name: 'ASSET_CONFIG' })}
          >
            {t('main.clients.menu.configMenu.asset-cost-centers')}
          </WorkflowLink>
          <WorkflowLink href={urls.WITHDRAW_CAPITAL_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.withdraw-capital')}
          </WorkflowLink>
          <WorkflowLink href={urls.IMPUTED_WITHDRAWAL_CAPITAL_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.imputed-withdrawal-capital')}
          </WorkflowLink>
          <WorkflowLink href={urls.INTEREST_RATES_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.interest-rates')}
          </WorkflowLink>
          <WorkflowLink href={urls.IMPUTED_WORKING_CAPITAL_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.imputed-working-capital')}
          </WorkflowLink>
        </AccordionItem>
        <AccordionItem
          aria-label={t('common.enums.configItemGroups.COST_UNIT_REPORT')}
          key="cost-unit"
          title={t('common.enums.configItemGroups.COST_UNIT_REPORT')}
        >
          <WorkflowLink href={urls.COST_UNITS_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.cost-units')}
          </WorkflowLink>
          <WorkflowLink
            href={urls.COST_UNITS_ALLOCATION_CONFIGURATION_PAGE(params)}
            status={getStatusValue({ configsStatus, name: 'COST_UNIT_ALLOCATION_CONFIG' })}
          >
            {t('main.clients.menu.configMenu.cost-units-allocation')}
          </WorkflowLink>
        </AccordionItem>
        <AccordionItem
          aria-label={t('common.enums.configItemGroups.PRICE_SHEET')}
          key="price-sheet"
          title={t('common.enums.configItemGroups.PRICE_SHEET')}
        >
          <WorkflowLink href={urls.PRICE_SHEET_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.price-sheet')}
          </WorkflowLink>
        </AccordionItem>
        <AccordionItem
          aria-label={t('main.clients.menu.fileMenu.lblOther')}
          key="file-menu"
          title={t('main.clients.menu.fileMenu.lblOther')}
        >
          <WorkflowLink href={urls.ASSET_FORECAST_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.asset-forecast')}
          </WorkflowLink>
          <WorkflowLink href={urls.FUTURE_ASSETS_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.future-assets')}
          </WorkflowLink>
          <WorkflowLink
            href={urls.PLANNED_COST_REVENUE_CONFIGURATION_PAGE(params)}
            status={getStatusValue({ configsStatus, name: 'PLANNED_COST_REVENUE' })}
          >
            {t('main.clients.menu.configMenu.planned-cost-revenue')}
          </WorkflowLink>
          <WorkflowLink
            href={urls.TECHNICAL_DATA_CONFIGURATION_PAGE(params)}
            status={getStatusValue({ configsStatus, name: 'TECHNICAL_DATA_CONFIG' })}
          >
            {t('main.clients.menu.configMenu.technical-data')}
          </WorkflowLink>
        </AccordionItem>
        <AccordionItem
          aria-label={t('common.enums.configItemGroups.LOGS')}
          key="logs"
          title={t('common.enums.configItemGroups.LOGS')}
        >
          <WorkflowLink href={urls.MANUAL_CORRECTIONS_BAB_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.manual-corrections-bab')}
          </WorkflowLink>
          <WorkflowLink href={urls.MANUAL_CORRECTIONS_INTERESTS_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.manual-corrections-interest')}
          </WorkflowLink>
          <WorkflowLink href={urls.TRANSACTION_MODIFICATIONS_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.bab-modifications')}
          </WorkflowLink>
          <WorkflowLink href={urls.CUSTOM_LABELS_CONFIGURATION_PAGE(params)}>
            {t('main.clients.menu.configMenu.custom-labels')}
          </WorkflowLink>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
