import type { ICellEditorParams } from '@ag-grid-community/core';

import DatePicker from 'react-date-picker';

import { formatToISOLocalDate } from '@org/utils';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface DatePickerCellEditorProps extends ICellEditorParams {
  value: string;
  onValueChange: (value: unknown) => void;
}

export const DatePickerCellEditor = (props: DatePickerCellEditorProps) => {
  const { value, onValueChange } = props;

  const handleChange = (newValue: Value) => {
    if (newValue && !Array.isArray(newValue)) {
      onValueChange(formatToISOLocalDate(newValue));
    }
  };

  return (
    <DatePicker
      format="dd.MM.y"
      onChange={handleChange}
      value={value ? new Date(value) : undefined}
    />
  );
};
