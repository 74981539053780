import type { ModalBodyProps, ModalHeaderProps, ModalProps } from '@nextui-org/react';
import { forwardRef } from 'react';

import {
  Modal as NextModal,
  ModalBody as NextModalBody,
  ModalHeader as NextModalHeader,
} from '@nextui-org/react';
import { twMerge } from 'tailwind-merge';

import styles from './Modal.module.css';

const Modal = forwardRef<HTMLElement, ModalProps>((props, ref) => {
  const {
    children,
    classNames: customClassNames,
    size = '4xl',
    isDismissable = false,
    ...rest
  } = props;

  const { body, ...otherClassnames } = customClassNames ?? {};

  const modalClassNames = {
    body: ['border-solid border-grey-light border-y-1'],
  };

  return (
    <NextModal
      classNames={{
        body: twMerge(modalClassNames.body, body),
        ...otherClassnames,
      }}
      isDismissable={isDismissable}
      placement="top-center"
      radius="none"
      ref={ref}
      size={size}
      {...rest}
    >
      {children}
    </NextModal>
  );
});

const FullSizeModal = forwardRef<HTMLElement, ModalProps>((props, ref) => {
  const { children, classNames: customClassNames, isDismissable = false, ...rest } = props;

  const { body, base, ...otherClassnames } = customClassNames ?? {};

  const modalClassNames = {
    base: ['h-[90vh]'],
    body: ['border-solid border-grey-light border-y-1'],
  };

  return (
    <NextModal
      classNames={{
        base: twMerge(styles['full-size-position'], modalClassNames.base, base),
        body: twMerge(modalClassNames.body, body),
        ...otherClassnames,
      }}
      isDismissable={isDismissable}
      placement="top-center"
      radius="none"
      ref={ref}
      size="full"
      {...rest}
    >
      {children}
    </NextModal>
  );
});

const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>((props, ref) => {
  const { className, children, ...rest } = props;
  return (
    <NextModalBody
      className={twMerge('py-4', className)}
      ref={ref}
      {...rest}
    >
      {children}
    </NextModalBody>
  );
});

const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>((props, ref) => {
  const { className, children, ...rest } = props;
  return (
    <NextModalHeader
      className={twMerge('flex flex-col gap-1', className)}
      ref={ref}
      {...rest}
    >
      {children}
    </NextModalHeader>
  );
});

export { ModalBody, ModalHeader, Modal, FullSizeModal };

export { ModalContent, ModalFooter } from '@nextui-org/react';
