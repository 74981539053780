export const NOT_AVAILABLE = 'NA';

export const INDEX_FACTORS_AVAILABLE_FROM_YEAR = 1947;

export const GROUP = 'GROUP';
export const SUB_GROUP = 'SUB_GROUP';
export const ENTRY = 'ENTRY';

export const RowTypes = [GROUP, SUB_GROUP, ENTRY] as const;
export type RowType = (typeof RowTypes)[number];

export const LABEL_FILE_ROW_TYPES = {
  ANLAGEN_KLASSE: {
    description: 'Anlagenklasse',
    key: 'ANLAGEN_KLASSE',
  },
  AUFTRAG: {
    description: 'Auftrag',
    key: 'AUFTRAG',
  },
  BEWERTUNGSBEREICH: {
    description: 'Bewertungsbereich',
    key: 'BEWERTUNGSBEREICH',
  },
  KST: {
    description: 'Kostenstelle',
    key: 'KST',
  },
  KST_BEREICH: {
    description: 'Kostenstellen Bereich',
    key: 'KST_BEREICH',
  },
  PROFITCENTER: {
    description: 'Profit Center',
    key: 'PROFITCENTER',
  },
  PROFITCENTER_BEREICH: {
    description: 'Profit Center Bereich',
    key: 'PROFITCENTER_BEREICH',
  },
  PROJEKT: {
    description: 'Projekt',
    key: 'PROJEKT',
  },
};

export const FILE_NAME_API_TYPE_MAP = {
  assets: 'ASSETS',
  journal: 'JOURNAL',
  kontenplan: 'ACCOUNT_PLAN',
  labels: 'LABELS',
  sachkonten: 'LEDGER_ACCOUNT',
} as const;

export const FILE_CONFIG = {
  ACCOUNT_PLAN: {
    apiType: 'ACCOUNT_PLAN',
    fields: {
      accountDescription: {
        description: 'Bezeichnung eines Sachkontos',
        inputName: 'konto_bez',
        internalName: 'accountDescription',
        optional: true,
        type: 'text',
      },
      accountId: {
        description: 'Nummer eines Sachkontos',
        inputName: 'konto_nr',
        internalName: 'accountId',
        optional: false,
        type: 'number',
      },
    },
    fileName: 'kontenplan',
    nameTranslationKey: 'common.files.accountPlan',
  },
  ASSETS: {
    apiType: 'ASSETS',
    fields: {
      acquisitionValueEnd: {
        description: 'Anschaffungswert Ende',
        inputName: 'anschaffungswert_ende',
        internalName: 'acquisitionValueEnd',
        optional: true,
        type: 'number',
      },
      assetCategory: {
        description: 'Nummer der Anlagenklasse',
        inputName: 'anlagen_klasse_nr',
        internalName: 'assetCategory',
        optional: false,
        type: 'text',
      },
      assetDepreciation: {
        description: 'Abschreibung der Anlage',
        inputName: 'anlage_afa',
        internalName: 'assetDepreciation',
        optional: false,
        type: 'number',
      },
      assetDescription: {
        description: 'Bezeichnung des Vermögensgegenstands',
        inputName: 'anlagenbezeichnung',
        internalName: 'assetDescription',
        optional: true,
        type: 'text',
      },
      assetNumber: {
        description: 'Eindeutige Nummer des Vermögensgegenstands',
        inputName: 'anlagennummer',
        internalName: 'assetNumber',
        optional: false,
        type: 'text',
      },
      assetSubNumber: {
        description: 'Unternummer der Anlage',
        inputName: 'anlagen_unr',
        internalName: 'assetAccount',
        optional: true,
        type: 'text',
      },
      clientsCostCenterName: {
        description: 'Kostenstelle der Anlage',
        inputName: 'anlage_kostenstelle_nr',
        internalName: 'clientsCostCenterName',
        optional: true,
        type: 'text',
      },
      depreciationHGB: {
        description: 'AfA Zugang des Jahres',
        inputName: 'afa_zugang_HGB',
        internalName: 'depreciationHGB',
        optional: false,
        type: 'number',
      },
      lifeTime: {
        description: 'Nutzungsdauer in Monaten',
        inputName: 'nutzungsdauer_in_monaten',
        internalName: 'lifeTime',
        optional: true,
        type: 'number',
      },
      purchasingDate: {
        description: 'Anschaffungsdatum',
        inputName: 'anschaffungsdatum',
        internalName: 'purchasingDate',
        optional: false,
        type: 'date',
      },
      rbvCapitalAssetsBegin: {
        description: 'Restbuchwert des Vermögensgegenstands zum Anfang des Wirtschaftsjahres',
        inputName: 'restbuchwert_beginn',
        internalName: 'rbvCapitalAssetsBegin',
        optional: false,
        type: 'number',
      },
      rbvCapitalAssetsEnd: {
        description: 'Restbuchwert des Vermögensgegenstands zum Ende des Wirtschaftsjahres',
        inputName: 'restbuchwert_ende',
        internalName: 'rbvCapitalAssetsEnd',
        optional: false,
        type: 'number',
      },
    },
    fileName: 'assets',
    nameTranslationKey: 'common.files.assets',
  },
  JOURNAL: {
    apiType: 'JOURNAL',
    fields: {
      accountId: {
        description: 'Kontonummer des Kontos',
        inputName: 'konto_nr',
        internalName: 'accountId',
        optional: false,
        type: 'number',
      },
      clientsCostCenterName: {
        description: 'Nummer der Kostenstelle auf die der Aufwand oder der Ertrag gebucht wurden',
        inputName: 'kostenstelle_nr',
        internalName: 'clientsCostCenterName',
        optional: true,
        type: 'text',
      },
      description: {
        description: 'Kurzbeschreibung zur Buchung',
        inputName: 'buchungstext',
        internalName: 'description',
        optional: true,
        type: 'text',
      },
      index: {
        description: 'Eindeutige Buchungsnummer (Buchungs ID)',
        inputName: 'index',
        internalName: 'index',
        optional: false,
        type: 'text',
      },
      jobId: {
        description: 'Nummer des Auftrags',
        inputName: 'auftrag_nr',
        internalName: 'jobId',
        optional: true,
        type: 'text',
      },
      profitCenterId: {
        description: 'Nummer des Profit Centers',
        inputName: 'profitcenter_nr',
        internalName: 'profitCenterId',
        optional: true,
        type: 'text',
      },
      transactionAmount: {
        description: 'Betrag der Buchung',
        inputName: 'betrag',
        internalName: 'transactionAmount',
        optional: false,
        type: 'number',
      },
      transactionType: {
        description: 'Typ der Transaktion',
        inputName: 'soll_haben',
        internalName: 'transactionType',
        optional: false,
        type: 'text',
      },
    },
    fileName: 'journal',
    nameTranslationKey: 'common.files.journal',
  },
  LABELS: {
    apiType: 'LABELS',
    fields: {
      description: {
        description: 'Description text',
        inputName: 'bez',
        internalName: 'description',
        optional: true,
        type: 'text',
      },
      number: {
        description:
          'Refers to either a cost center name (kostenstelle_nr in Journal.csv) or a job id (auftrag_nr in Journal.csv)',
        inputName: 'nr',
        internalName: 'number',
        optional: false,
        type: 'text',
      },
      type: {
        description: `Identifies the type of this row with following values: ${Object.values(
          LABEL_FILE_ROW_TYPES,
        )
          .map((type) => `${type.key} (${type.description})`)
          .join(', ')}`,
        inputName: 'typ',
        internalName: 'type',
        optional: false,
        type: 'text',
      },
    },
    fileName: 'labels',
    nameTranslationKey: 'common.files.labels',
  },
  LEDGER_ACCOUNT: {
    apiType: 'LEDGER_ACCOUNT',
    fields: {
      accountId: {
        description: 'Nummer des Sachkontos',
        inputName: 'konto_nr',
        internalName: 'accountId',
        optional: true,
        type: 'number',
      },
      assetAccount: {
        description: 'Abzugkapital-Unternummer',
        inputName: 'anlagen_unr',
        internalName: 'assetAccount',
        optional: true,
        type: 'number',
      },
      assetCategory: {
        description: 'Kürzel der Abzugskapitalklasse/-gruppe',
        inputName: 'klasse_nr',
        internalName: 'assetCategory',
        optional: true,
        type: 'text',
      },
      assetDescription: {
        description: 'Bezeichnung des Abzugkapitals',
        inputName: 'anlagen_bez',
        internalName: 'assetDescription',
        optional: true,
        type: 'text',
      },
      assetNumber: {
        description: 'Eindeutige Abzugkapital ID/ Abzugkapitalnummer',
        inputName: 'anlagen_nr',
        internalName: 'assetNumber',
        optional: true,
        type: 'number',
      },
      clientsCostCenterName: {
        description: 'Nummer der Kostenstelle',
        inputName: 'kst_nr',
        internalName: 'clientsCostCenterName',
        optional: true,
        type: 'text',
      },
      rbvCapitalAssetsBegin: {
        description: 'Buchwert zum 01.01. des Jahres',
        inputName: 'bw_anfang',
        internalName: 'rbvCapitalAssetsBegin',
        optional: true,
        type: 'number',
      },
      rbvCapitalAssetsEnd: {
        description: 'Buchwert zum 31.12. des Jahres',
        inputName: 'bw_ende',
        internalName: 'rbvCapitalAssetsEnd',
        optional: true,
        type: 'number',
      },
    },
    fileName: 'sachkonten',
    nameTranslationKey: 'common.files.generalAccounts',
  },
} as const;

export const FILE_TYPES = [
  FILE_CONFIG.ASSETS.apiType,
  FILE_CONFIG.ACCOUNT_PLAN.apiType,
  FILE_CONFIG.JOURNAL.apiType,
  FILE_CONFIG.LABELS.apiType,
  FILE_CONFIG.LEDGER_ACCOUNT.apiType,
] as const;

export type FileType = (typeof FILE_TYPES)[number];
