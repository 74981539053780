import type { SVGProps } from 'react';
import { memo } from 'react';

const UsFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 7410 3900"
    width="1.51em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="#b22234"
      height="3900"
      width="7410"
    />
    <path
      d="M0,450H7410m0,600H0m0,600H7410m0,600H0m0,600H7410m0,600H0"
      stroke="#fff"
      strokeWidth="300"
    />
    <rect
      fill="#3c3b6e"
      height="2100"
      width="2964"
    />
    <g fill="#fff">
      <g id="s18">
        <g id="s9">
          <g id="s5">
            <g id="s4">
              <path
                d="M247,90 317.534230,307.082039 132.873218,172.917961H361.126782L176.465770,307.082039z"
                id="s"
              />
              <use
                href="#s"
                y="420"
              />
              <use
                href="#s"
                y="840"
              />
              <use
                href="#s"
                y="1260"
              />
            </g>
            <use
              href="#s"
              y="1680"
            />
          </g>
          <use
            href="#s4"
            x="247"
            y="210"
          />
        </g>
        <use
          href="#s9"
          x="494"
        />
      </g>
      <use
        href="#s18"
        x="988"
      />
      <use
        href="#s9"
        x="1976"
      />
      <use
        href="#s5"
        x="2470"
      />
    </g>
  </svg>
);

export default memo(UsFlag);
