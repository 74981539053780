import type { ICellRendererParams } from '@ag-grid-community/core';
import type { ChangeEvent } from 'react';
import { memo, useCallback } from 'react';

import type { CorrectAny } from '../../../../models/src/types';
import { CheckboxInput } from '../CheckboxInput';

export interface CheckboxHeaderCellRendererParams<TData = CorrectAny, TContext = CorrectAny>
  extends ICellRendererParams<TData, boolean, TContext> {
  /** Set to `true` for the input to be disabled. */
  disabled?: boolean;
  indeterminate?: boolean;
  label?: string;
  /**
   * On edit callback.
   * @param value
   * @returns
   */
  onEdit?: (value: boolean) => void;
}

const CheckboxHeaderCellRendererMemo = (props: CheckboxHeaderCellRendererParams) => {
  const { onEdit, label, indeterminate, disabled, value } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onEdit?.(event.currentTarget.checked);
    },
    [onEdit],
  );

  return (
    <div className="flex h-full items-center justify-center">
      <CheckboxInput
        checked={value ?? false}
        disabled={Boolean(disabled)}
        indeterminate={indeterminate}
        onChange={handleChange}
      >
        {label ? <span className="pl-1">{label}</span> : ''}
      </CheckboxInput>
    </div>
  );
};

export const CheckboxHeaderCellRenderer = memo(CheckboxHeaderCellRendererMemo);
