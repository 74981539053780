import type { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import styles from './Divider.module.css';

type OuterProps = React.JSX.IntrinsicElements['div'];

export type DividerProps = {
  vertical?: boolean;
} & OuterProps;

export const Divider: FC<DividerProps> = (props) => {
  const { className, vertical, ...otherProps } = props;
  return (
    <div
      className={twMerge(
        vertical && 'mx-3 h-full w-px shrink-0 grow-0',
        !vertical && 'my-5 h-px w-full flex-1',
        'inline-block bg-[#eee]',
        styles.Divider,
        className,
      )}
      data-testid="Divider"
      {...otherProps}
    />
  );
};
