'use client';

import { useRouter } from 'next/navigation';

import { useLocale, useTranslations } from 'next-intl';

import { Combobox, ComboboxItem } from '@org/design';
import { GermanFlag, UsFlag } from '@org/ui';

import { changeLanguage } from '~/app/actions';

export const LanguageSelect = () => {
  const t = useTranslations();
  const router = useRouter();
  const locale = useLocale();

  return (
    <Combobox
      aria-label="Select language"
      onSelectionChange={async (key) => {
        await changeLanguage(key?.toString());
        router.refresh();
      }}
      selectedKey={locale}
      startContent={
        <div className="flex items-center justify-center">
          {locale === 'de' ? <GermanFlag /> : <UsFlag />}
        </div>
      }
    >
      <ComboboxItem
        key="de"
        textValue={t('common.german')}
      >
        <div className="flex flex-row items-center gap-2">
          <GermanFlag />
          {t('common.german')}
        </div>
      </ComboboxItem>
      <ComboboxItem
        key="en"
        textValue={t('common.english')}
      >
        <div className="flex flex-row items-center gap-2">
          <UsFlag />
          {t('common.english')}
        </div>
      </ComboboxItem>
    </Combobox>
  );
};
