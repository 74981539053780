import * as reactQuery from '@tanstack/react-query';

import type * as Fetcher from './productIamFetcher';
import { productIamFetch } from './productIamFetcher';

export interface InviteHeaders {}
export type InviteError = Fetcher.ErrorWrapper<undefined>;

export interface ProductIamContext {
  fetcherOptions: {
    /**
     * Headers to inject in the fetcher
     */
    headers?: {};
    /**
     * Query params to inject in the fetcher
     */
    queryParams?: {};
  };
  queryOptions: {
    /**
     * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
     * Defaults to `true`.
     */
    enabled?: boolean;
  };
}

export interface InviteRequest {
  email: string;
}

export interface InviteResponse {
  description: string;
  status: 'SUCCESS' | (string & {});
}

export type InviteVariables = {
  body: InviteRequest;
  headers?: InviteHeaders;
} & ProductIamContext['fetcherOptions'];

export const fetchInvite = (variables: InviteVariables, signal?: AbortSignal) =>
  productIamFetch<InviteResponse, InviteError, InviteRequest, InviteHeaders, {}, {}>({
    url: '/invite',
    method: 'post',
    ...variables,
    signal,
  });

export const useInvite = (
  options?: Omit<
    reactQuery.UseMutationOptions<InviteResponse, InviteError, InviteVariables>,
    'mutationFn'
  >,
) =>
  reactQuery.useMutation<InviteResponse, InviteError, InviteVariables>({
    mutationFn: (variables: InviteVariables) => fetchInvite({ ...variables }),
    ...options,
  });
