import type { FC } from 'react';
import Link from 'next/link';

import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

import { HOME_PAGE } from '../../urls';

type OuterProps = React.JSX.IntrinsicElements['ul'];

export interface BreadcrumbProps {
  href?: string;
  text: string;
}

export type BreadcrumbsProps = {
  items?: BreadcrumbProps[];
} & OuterProps;

const useBreads = (extraCrumbs: BreadcrumbProps[], current: string): BreadcrumbProps[] => {
  // const t = useTranslations();
  const breadcrumbs: BreadcrumbProps[] = [
    {
      href: HOME_PAGE({}),
      target: HOME_PAGE({}),
      text: 'Home page',
    },
    ...extraCrumbs,
    {
      current: true,
      text: current,
    },
  ].filter(({ text }) => !!text);

  const showBreadcrumbs = breadcrumbs.filter(
    ({ text }, index) =>
      !breadcrumbs.some((last, lastIndex) => last.text === text && index !== lastIndex),
  );

  return showBreadcrumbs;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { className, children, items = [], ...otherProps } = props;
  const breadcrumbsToShow = useBreads(items, children as string);

  return (
    <ul
      className={twMerge(className, 'grid grid-flow-col justify-start text-sm')}
      data-testid="Breadcrumbs"
      {...otherProps}
    >
      {breadcrumbsToShow.map((item, index) => (
        <li
          className="flex items-center"
          key={`${item.href}${item.text}`}
        >
          {item.href ? <Link href={item.href}>{item.text}</Link> : <span>{item.text}</span>}

          {breadcrumbsToShow.length > 1 && breadcrumbsToShow.length - 1 > index && (
            <ChevronRightIcon className="mx-3 h-5 text-gray-400" />
          )}
        </li>
      ))}
    </ul>
  );
};
