import type { FetcherOptions } from '../../common';
import type { RaveContext } from './raveContext';
import { buildFetcher } from '../../common';

const baseUrl = '/raveApi';

export type RaveFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = FetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
> &
  RaveContext['fetcherOptions'];

export const raveFetch = buildFetcher(baseUrl);

export { type ErrorWrapper } from '../../common';
