import { formatNumberToLocaleString } from './formatNumberToLocaleString';

export type FormatNonZeroNumberToLocaleStringOptions = {
  defaultValue?: string;
} & Intl.NumberFormatOptions;

export const formatNonZeroNumberToLocaleString = (
  value?: number | string | null,
  options?: FormatNonZeroNumberToLocaleStringOptions,
) => {
  const { defaultValue } = options ?? {};
  let internalValue = value;

  // If the value is a number and is very close to 0, set it to 0
  if (typeof value === 'number' && Math.abs(value) < 0.001) {
    internalValue = 0;
  }

  return internalValue ? formatNumberToLocaleString(value, options) : (defaultValue ?? '');
};
