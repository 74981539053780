import type { ICellRendererParams } from '@ag-grid-community/core';
import type { FC } from 'react';
import { memo } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ButtonCellRendererProps } from '../ButtonCellRenderer';
import { ButtonCellRenderer } from '../ButtonCellRenderer';

export type MultipleButtonRendererProps = {
  buttonParams?: ButtonCellRendererProps[];
  className?: string;
} & ICellRendererParams;

export const MultipleButtonsMemo: FC<MultipleButtonRendererProps> = (props) => {
  const { className, ...restProps } = props;
  return (
    <div className={twMerge('flex h-full flex-row items-center justify-end', className)}>
      {props.buttonParams?.map((prop, index) => (
        <ButtonCellRenderer
          disableWrapper
          {...props}
          {...prop}
          data={props.data}
          gridProps={restProps}
          key={index}
        />
      ))}
    </div>
  );
};

export const MultipleButtonsRenderer = memo(MultipleButtonsMemo);
