/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from '@tanstack/react-query';

import type { RaveContext } from './raveContext';
import type * as Fetcher from './raveFetcher';
import type * as Schemas from './raveSchemas';
import { queryKeyFn, useRaveContext } from './raveContext';
import { raveFetch } from './raveFetcher';

export interface CalculateReportsHeaders {
  authorization?: string;
}

export type CalculateReportsError = Fetcher.ErrorWrapper<undefined>;

export type CalculateReportsVariables = {
  body: Schemas.ReportsControllerRequest;
  headers?: CalculateReportsHeaders;
} & RaveContext['fetcherOptions'];

export const fetchCalculateReports = (variables: CalculateReportsVariables, signal?: AbortSignal) =>
  raveFetch<
    Schemas.ReportsResponse,
    CalculateReportsError,
    Schemas.ReportsControllerRequest,
    CalculateReportsHeaders,
    {},
    {}
  >({ url: '/reports', method: 'post', ...variables, signal });

export const useCalculateReports = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ReportsResponse,
      CalculateReportsError,
      CalculateReportsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useRaveContext();
  return reactQuery.useMutation<
    Schemas.ReportsResponse,
    CalculateReportsError,
    CalculateReportsVariables
  >({
    mutationFn: (variables: CalculateReportsVariables) =>
      fetchCalculateReports({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllTransactionsForAccountsQueryParams {
  accountIds: string[];
  yearId: string;
}

export type GetAllTransactionsForAccountsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllTransactionsForAccountsResponse = Schemas.AccountTransaction[];

export type GetAllTransactionsForAccountsVariables = {
  body: Schemas.MasterConfiguration;
  queryParams: GetAllTransactionsForAccountsQueryParams;
} & RaveContext['fetcherOptions'];

export const fetchGetAllTransactionsForAccounts = (
  variables: GetAllTransactionsForAccountsVariables,
  signal?: AbortSignal,
) =>
  raveFetch<
    GetAllTransactionsForAccountsResponse,
    GetAllTransactionsForAccountsError,
    Schemas.MasterConfiguration,
    {},
    GetAllTransactionsForAccountsQueryParams,
    {}
  >({ url: '/account', method: 'post', ...variables, signal });

export const useGetAllTransactionsForAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetAllTransactionsForAccountsResponse,
      GetAllTransactionsForAccountsError,
      GetAllTransactionsForAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useRaveContext();
  return reactQuery.useMutation<
    GetAllTransactionsForAccountsResponse,
    GetAllTransactionsForAccountsError,
    GetAllTransactionsForAccountsVariables
  >({
    mutationFn: (variables: GetAllTransactionsForAccountsVariables) =>
      fetchGetAllTransactionsForAccounts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type HealthcheckError = Fetcher.ErrorWrapper<undefined>;

export type HealthcheckVariables = RaveContext['fetcherOptions'];

export const fetchHealthcheck = (variables: HealthcheckVariables, signal?: AbortSignal) =>
  raveFetch<string, HealthcheckError, undefined, {}, {}, {}>({
    url: '/healthcheck',
    method: 'get',
    ...variables,
    signal,
  });

export const healthcheckQuery = (
  variables: HealthcheckVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<string>;
} => ({
  queryKey: queryKeyFn({
    path: '/healthcheck',
    operationId: 'healthcheck',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchHealthcheck({ ...variables }, signal),
});

export const useSuspenseHealthcheck = <TData = string>(
  variables: HealthcheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, HealthcheckError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useRaveContext(options);
  return reactQuery.useSuspenseQuery<string, HealthcheckError, TData>({
    ...healthcheckQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useHealthcheck = <TData = string>(
  variables: HealthcheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, HealthcheckError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useRaveContext(options);
  return reactQuery.useQuery<string, HealthcheckError, TData>({
    ...healthcheckQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type BaseError = Fetcher.ErrorWrapper<undefined>;

export type BaseVariables = RaveContext['fetcherOptions'];

export const fetchBase = (variables: BaseVariables, signal?: AbortSignal) =>
  raveFetch<string, BaseError, undefined, {}, {}, {}>({
    url: '/',
    method: 'get',
    ...variables,
    signal,
  });

export const baseQuery = (
  variables: BaseVariables,
): {
  queryKey: reactQuery.QueryKey;
  queryFn: ({ signal }: { signal?: AbortSignal }) => Promise<string>;
} => ({
  queryKey: queryKeyFn({
    path: '/',
    operationId: 'base',
    variables,
  }),
  queryFn: ({ signal }: { signal?: AbortSignal }) => fetchBase({ ...variables }, signal),
});

export const useSuspenseBase = <TData = string>(
  variables: BaseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, BaseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useRaveContext(options);
  return reactQuery.useSuspenseQuery<string, BaseError, TData>({
    ...baseQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export const useBase = <TData = string>(
  variables: BaseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, BaseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { queryOptions } = useRaveContext(options);
  return reactQuery.useQuery<string, BaseError, TData>({
    ...baseQuery(variables),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: '/healthcheck';
      operationId: 'healthcheck';
      variables: HealthcheckVariables;
    }
  | {
      path: '/';
      operationId: 'base';
      variables: BaseVariables;
    };
