import type { NumericFormatProps } from 'react-number-format';
import { forwardRef } from 'react';

import { NumericFormat } from 'react-number-format';
import { twMerge } from 'tailwind-merge';

const LOCALIZED_SETTINGS = {
  de: {
    decimalSeparator: ',',
    thousandSeparator: '.',
  },
};

const MAX_INTEGER_LENGTH = 12;
const MAX_FLOAT_LENGTH = 17;

export type NumberInputProps = {
  onChange: (value: number | undefined) => void;
} & Omit<NumericFormatProps, 'onChange'>;

export const NumberInput = forwardRef<unknown, NumberInputProps>((props, ref) => {
  const { className, decimalScale, onChange, ...otherProps } = props;
  return (
    <NumericFormat
      className={twMerge('input h-[34px] px-2.5', className)}
      decimalSeparator={LOCALIZED_SETTINGS.de.decimalSeparator}
      getInputRef={ref}
      maxLength={decimalScale === 0 ? MAX_INTEGER_LENGTH : MAX_FLOAT_LENGTH}
      onValueChange={({ floatValue }) => onChange(floatValue)}
      thousandSeparator={LOCALIZED_SETTINGS.de.thousandSeparator}
      {...otherProps}
    />
  );
});
