import type {
  CellClassRules,
  ColDef,
  ExcelExportParams,
  IAggFunc,
  ValueGetterParams,
  ValueParserParams,
} from '@ag-grid-community/core';
import type { AgGridReactProps } from '@ag-grid-community/react';
import type { RefObject } from 'react';
import { forwardRef, useMemo } from 'react';

import { AgGridReact } from '@ag-grid-community/react';
import { twMerge } from 'tailwind-merge';

import {
  formatIntegerToLocaleString,
  formatNonZeroNumberToLocaleString,
  formatToSimpleDate,
  formatValue,
  initialGroupOrderComparator,
  parseGermanPriceFormat,
  preciseSum,
  valueFormatterZero,
} from '@org/utils';

import { ButtonCellRenderer } from '../ButtonCellRenderer';
import { CheckboxCellRenderer, CheckboxHeaderCellRenderer } from '../CheckboxCellRenderer';
import { DatePickerCellEditor } from '../DatePickerCellEditor';
import { MultipleButtonsRenderer } from '../MultipleButtonsRenderer';
import { NumericCellField } from '../NumericCellField';
import { SelectCellEditor } from '../SelectCellEditor';
import { TooltipCellRenderer } from '../TooltipCellRenderer';
import { defaultExcelStyles } from './utils/defaultExcelStyles';

import './ag-grid.css';

import styles from './BaseTable.module.css';

const DEFAULT_SHEET_NAME = 'Daten';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface BaseTableProps<TData = any> extends AgGridReactProps<TData> {
  ref?: RefObject<AgGridReact>;

  isDisabled?: boolean;
}

type BaseTableComponent = <Data>(
  props: BaseTableProps<Data>,
  ref: RefObject<AgGridReact>,
) => React.JSX.Element | null;

const defaultColTypes = {
  date: {
    cellEditorPopup: true,
    cellEditorPopupPosition: 'under',
    cellClass: 'ag-cell-type-date',
    valueFormatter: ({ value }) => (value ? formatToSimpleDate(new Date(value)) : ''),
    cellEditor: 'datePicker',
  },
  float: {
    cellClass: 'ag-cell-type-float',
    valueFormatter: valueFormatterZero,
    cellEditor: 'numeric',
  },
  integer: {
    cellClass: 'ag-cell-type-integer',
    valueFormatter: ({ value }) =>
      value
        ? formatIntegerToLocaleString(value, {
            defaultValue: '-',
          })
        : '-',
    cellEditorParams: {
      decimalScale: 0,
    },
    cellEditor: 'numeric',
  },
  number: {
    cellClass: 'ag-cell-type-number',
    valueFormatter: valueFormatterZero,
    cellEditor: 'numeric',
  },
  percent: {
    cellClass: 'ag-cell-type-float',
    valueFormatter: valueFormatterZero,
    valueGetter: (params: ValueGetterParams) => {
      const fieldName = params.colDef.field!;
      return Number.parseFloat(params.data?.[fieldName].toFixed(2));
    },
    valueParser: ({ newValue }: ValueParserParams) => (newValue as unknown as number).toFixed(2),
  },
  price: {
    cellClass: 'ag-cell-type-price',
    valueFormatter: valueFormatterZero,
    valueParser: ({ newValue }) => parseGermanPriceFormat(newValue),
    cellEditor: 'numeric',
  },
  'price-with-default-zero': {
    cellClass: 'ag-cell-type-price',
    valueFormatter: ({ value }) =>
      formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
    valueParser: ({ newValue }) => parseGermanPriceFormat(newValue),
    cellEditor: 'numeric',
  },
  'report-price': {
    cellClass: ['ag-cell-type-report-price', 'accounting-number'],
    valueFormatter: valueFormatterZero,
    valueParser: ({ newValue }) => parseGermanPriceFormat(newValue),
    cellEditor: 'numeric',
  },
  select: {
    cellEditor: 'select',
  },
  text: {
    cellClass: 'ag-cell-type-text',
  },
} satisfies Record<string, ColDef>;

const customAggFunctions: Record<string, IAggFunc> = {
  preciseSum: ({ values }) => preciseSum(values),
};

// Workaround for jumping columns
const processUnpinnedColumns = () => [];

const Table = forwardRef<AgGridReact, BaseTableProps>((properties, ref) => {
  const {
    className,
    defaultColDef,
    excelStyles,
    defaultExcelExportParams: excelExportParameters,
    columnTypes,
    rowModelType,
    rowSelection,
    components,
    isDisabled,
    ...otherProperties
  } = properties;

  const cellClassRules: CellClassRules = useMemo(
    () => ({
      'ag-editable': (params) => {
        if (!params.colDef.editable) {
          return false;
        }
        if (params.colDef.editable instanceof Function) {
          return params.colDef.editable({ ...params });
        }
        return true;
      },
      ...defaultColDef?.cellClassRules,
    }),
    [defaultColDef?.cellClassRules],
  );

  const combinedExcelStyles = useMemo(
    () => [...defaultExcelStyles, ...(excelStyles ?? [])],
    [excelStyles],
  );

  const combinedDefaultExcelExportParameters = useMemo(
    () =>
      ({
        sheetName: DEFAULT_SHEET_NAME,
        ...excelExportParameters,
      }) satisfies ExcelExportParams,
    [excelExportParameters],
  );

  const defaultColDefCombined: ColDef = useMemo(
    () => ({
      singleClickEdit: true,
      suppressHeaderMenuButton: true,
      autoHeaderHeight: true,
      resizable: true,
      sortable: true,
      suppressMovable: true,
      tooltipComponent: TooltipCellRenderer,
      tooltipValueGetter: (parameters) => formatValue(parameters.value),
      type: 'text',
      useValueFormatterForExport: true,
      ...defaultColDef,
      cellClassRules,
    }),
    [cellClassRules, defaultColDef],
  );

  const notServerSideProps = useMemo(() => {
    if (rowModelType !== 'serverSide') {
      return {
        // Black magic for ordering group rows
        initialGroupOrderComparator,
      };
    }
  }, [rowModelType]);

  return (
    <div
      className={twMerge(
        'ag-theme-balham',
        'ag-grid',
        isDisabled && 'ag-disabled',
        styles.BaseTable,
        className,
      )}
    >
      <AgGridReact
        aggFuncs={customAggFunctions}
        animateRows
        columnTypes={useMemo(
          () => ({
            ...defaultColTypes,
            ...columnTypes,
          }),
          [columnTypes],
        )}
        components={useMemo(
          () => ({
            ...components,
            button: ButtonCellRenderer,
            checkbox: CheckboxCellRenderer,
            headerCheckbox: CheckboxHeaderCellRenderer,
            datePicker: DatePickerCellEditor,
            multipleButtons: MultipleButtonsRenderer,
            numeric: NumericCellField,
            select: SelectCellEditor,
          }),
          [components],
        )}
        defaultColDef={defaultColDefCombined}
        defaultExcelExportParams={combinedDefaultExcelExportParameters}
        enableCellTextSelection
        excelStyles={combinedExcelStyles}
        groupAllowUnbalanced
        groupHeaderHeight={23}
        headerHeight={23}
        processUnpinnedColumns={processUnpinnedColumns}
        ref={ref}
        rowModelType={rowModelType}
        rowSelection={useMemo(() => {
          if (!rowSelection || typeof rowSelection === 'string') {
            return rowSelection;
          }
          return {
            suppressRowClickSelection: true,
            ...rowSelection,
          };
        }, [rowSelection])}
        stopEditingWhenCellsLoseFocus
        suppressAggFuncInHeader
        suppressFieldDotNotation
        {...notServerSideProps}
        {...otherProperties}
      />
    </div>
  );
});
Table.displayName = 'BaseTable';

export const BaseTable = Table as BaseTableComponent;
