import type { SVGProps } from 'react';
import { memo } from 'react';

const GermanFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    height="1em"
    viewBox="0 0 5 3"
    width="1.6em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      fill="#000"
      height="3"
      id="black_stripe"
      width="5"
      x="0"
      y="0"
    />
    <rect
      fill="#D00"
      height="2"
      id="red_stripe"
      width="5"
      x="0"
      y="1"
    />
    <rect
      fill="#FFCE00"
      height="1"
      id="gold_stripe"
      width="5"
      x="0"
      y="2"
    />
  </svg>
);

export default memo(GermanFlag);
