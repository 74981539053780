import { useCallback } from 'react';

import { useTranslations } from 'next-intl';

import type { APIParams } from '../../..';
import type { MasterConfiguration, YearDTOV2 } from '../../dice';
import { useCalculateReports } from '../../rave';
import { usePlannedCostRevenue } from '../usePlannedCostRevenue';

export interface UseRecalculateReportProps
  extends APIParams<
    'publicFacilityId' | 'clientId' | 'yearId' | 'type' | 'masterConfigurationId'
  > {}

export const useRecalculateReport = (params: UseRecalculateReportProps) => {
  const { mutateAsync: recalculateReportMutation, isPending: isRecalculating } =
    useCalculateReports();

  const { plannedCostRevenueDetail } = usePlannedCostRevenue(params);
  const t = useTranslations();

  const recalculateReport = useCallback(
    async ({
      masterConfiguration,
    }: {
      year?: YearDTOV2;
      masterConfiguration?: MasterConfiguration;
    }) => {
      if (!params.yearId) {
        throw new Error('Year is required for recalculation');
      }
      if (
        plannedCostRevenueDetail?.plannedCostRevenueEnabled &&
        !plannedCostRevenueDetail.priceIncreaseMasterConfigurationId
      ) {
        throw new Error(
          t('main.manageMenu.managePlannedCostRevenue.alerts.warning.noConfigSelected'),
        );
      }

      await recalculateReportMutation({
        body: {
          masterConfiguration,
          yearId: params.yearId,
        },
      });
    },
    [
      params.yearId,
      plannedCostRevenueDetail?.plannedCostRevenueEnabled,
      plannedCostRevenueDetail?.priceIncreaseMasterConfigurationId,
      recalculateReportMutation,
      t,
    ],
  );

  return {
    isRecalculating,
    recalculateReport,
  };
};
