import { makeRoutePath } from 'make-route-path';

type StringOnly<T> = {
  [name in keyof T]: Exclude<T[name], number>;
};

export const HOME_PAGE = makeRoutePath('/');

export const CLIENT_PAGE = makeRoutePath('/client/:clientId');

export type ClientPagePath = StringOnly<Parameters<typeof CLIENT_PAGE>[0]>;

export const CLIENT_PUBLIC_FACILITY_PAGE = makeRoutePath('/client/:clientId/:publicFacilityId');

export type ClientPublicFacilityPagePath = StringOnly<
  Parameters<typeof CLIENT_PUBLIC_FACILITY_PAGE>[0]
>;

export const CLIENT_PUBLIC_FACILITY_YEAR_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type',
);

export type ClientPublicFacilityYearPagePath = StringOnly<
  Parameters<typeof CLIENT_PUBLIC_FACILITY_YEAR_PAGE>[0]
>;

export const CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/asset-forecast',
);

export type ClientPublicFacilityConfigurationPage = StringOnly<
  Parameters<typeof CONFIGURATION_PAGE>[0]
>;

export const EXPENSE_REVENUE_ACCOUNTS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/expense-and-revenue-accounts',
);

export const COST_TYPE_ACCOUNTS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/cost-type-accounts',
);

export const COST_CENTERS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/cost-centers',
);

export const EXPENSE_REVENUE_COST_CENTERS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/expense-and-revenue-cost-centers',
);

export const ASSET_COST_CENTERS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/asset-cost-centers',
);

export const ALLOCATION_KEYS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/allocation-keys',
);

export const COST_UNITS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/cost-units',
);

export const PRICE_SHEET_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/price-sheet',
);

export const COST_UNITS_ALLOCATION_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/cost-units-allocation',
);

export const OPERATING_ASSETS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/operating-assets',
);

export const FUTURE_ASSETS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/future-assets',
);

export const WITHDRAW_CAPITAL_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/withdraw-capital',
);

export const PLANNED_COST_REVENUE_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/planned-cost-revenue',
);

export const IMPUTED_WITHDRAWAL_CAPITAL_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/imputed-withdrawal-capital',
);

export const INTEREST_RATES_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/interest-rates',
);

export const IMPUTED_WORKING_CAPITAL_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/imputed-working-capital',
);

export const IMPUTED_CONCESSION_FEE_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/imputed-concession-fee',
);

export const CORPORATE_RISK_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/corporate-risk',
);

export const ASSET_FORECAST_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/asset-forecast',
);

export const IMPUTED_TRADE_TAX_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/imputed-trade-tax',
);

export const MANUAL_CORRECTIONS_BAB_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/manual-corrections-bab',
);

export const MANUAL_CORRECTIONS_INTERESTS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/manual-corrections-interest',
);

export const TRANSACTION_MODIFICATIONS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/bab-modifications',
);

export const TECHNICAL_DATA_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/technical-data',
);

export const BAB_CHANGELOG_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/bab-changelog',
);

export const CUSTOM_LABELS_CONFIGURATION_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/configuration/custom-labels',
);

export const BAB_REPORT_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/report/bab',
);

export const PRICE_SHEET_REPORT_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/report/price-sheet',
);

export const TRADE_TAX_REPORT_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/report/trade-tax',
);

export const INTEREST_REPORT_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/report/interest',
);

export const COST_UNIT_REPORT_PAGE = makeRoutePath(
  '/client/:clientId/:publicFacilityId/:yearId/:type/:masterConfigurationId/report/cost-unit',
);

/**
 * System pages
 */
export const AUTH_ERROR_PAGE = makeRoutePath('/auth-error');
