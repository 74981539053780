/* eslint-disable jsx-a11y/no-autofocus */
import type { ICellEditorParams } from '@ag-grid-community/core';
import { useCallback } from 'react';

import { useGridCellEditor } from '@ag-grid-community/react';
import { useTranslations } from 'next-intl';

import { ComboboxItem, ComboboxSection, MultipleCombobox, SingleCombobox } from '@org/design';

export interface SelectInputOption {
  label: string;
  value: string | number;
  disabled?: boolean;
}

export interface SelectGroupInputOption {
  label: string;
  options: SelectInputOption[];
}

export type SelectInputPossibleOptions =
  | number
  | string
  | SelectInputOption
  | SelectGroupInputOption;

interface SelectCellEditorProps extends ICellEditorParams {
  onValueChange: (value: unknown) => void;
  isMulti?: boolean;
  options: SelectInputPossibleOptions[];
  disabledOptions?: string[];
}

export const SelectCellEditor = (props: SelectCellEditorProps) => {
  const { value, onValueChange, stopEditing, eventKey, isMulti, options, disabledOptions, colDef } =
    props;

  const t = useTranslations();

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  const isCancelBeforeStart = useCallback(
    () => !!eventKey && eventKey.length === 1 && !'1234567890'.includes(eventKey),
    [eventKey],
  );

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  const isCancelAfterEnd = useCallback(
    () =>
      // will reject the number if it greater than 1,000,000
      // not very practical, but demonstrates the method.
      value != null && value > 1_000_000,
    [value],
  );

  useGridCellEditor({
    isCancelAfterEnd,
    isCancelBeforeStart,
  });

  const optionsNodes = options.map((option) => {
    if (typeof option === 'number' || typeof option === 'string') {
      return (
        <ComboboxItem
          key={option}
          textValue={option.toString()}
        >
          {option}
        </ComboboxItem>
      );
    }
    const { label } = option;
    if ('options' in option) {
      return (
        <ComboboxSection
          key={label}
          title={label}
        >
          {option.options.map((subOption) => (
            <ComboboxItem
              key={subOption.value}
              textValue={subOption.label.toString()}
            >
              {subOption.label}
            </ComboboxItem>
          ))}
        </ComboboxSection>
      );
    }
    return (
      <ComboboxItem
        key={option.value}
        textValue={label.toString()}
      >
        {label}
      </ComboboxItem>
    );
  });

  if (isMulti) {
    return (
      <MultipleCombobox
        aria-label={colDef.headerName}
        autoFocus
        disabledKeys={disabledOptions}
        formatSelectedText={({ selectedItems, collection }) => {
          if (selectedItems.length === collection.size) {
            return t('common.select.allSelected');
          }
          return t('common.select.selected', { count: selectedItems.length, of: collection.size });
        }}
        isFullWidth
        onSelectionChange={(keys) => {
          if (keys === 'all') {
            onValueChange(
              options.flatMap((currentOption) => {
                if (typeof currentOption === 'number' || typeof currentOption === 'string') {
                  return [currentOption];
                }
                if ('options' in currentOption) {
                  return currentOption.options.map((subOption) => subOption.value);
                }
                return [currentOption.value];
              }),
            );
            stopEditing();
            return;
          }
          onValueChange([...keys]);
          stopEditing();
        }}
        selectedKeys={value}
        size="sm"
      >
        {optionsNodes}
      </MultipleCombobox>
    );
  }
  return (
    <SingleCombobox
      aria-label={colDef.headerName}
      autoFocus
      disabledKeys={disabledOptions}
      isFullWidth
      onSelectionChange={(item) => {
        onValueChange(item);
        stopEditing();
      }}
      selectedKey={value}
      size="sm"
    >
      {optionsNodes}
    </SingleCombobox>
  );
};
