import type { ITooltipParams } from '@ag-grid-community/core';
import type { FC } from 'react';
import { memo } from 'react';

export const TooltipCellRenderer: FC<ITooltipParams> = memo((props) => {
  const { value } = props;

  if (typeof value !== 'string' && typeof value !== 'number') {
    return null;
  }

  return <div className="rounded-sm bg-black/70 p-2 text-white">{value}</div>;
});
