import type { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import styles from './Container.module.css';

type OuterProps = React.JSX.IntrinsicElements['div'];

export type ContainerProps = {
  behavior?: 'fill' | 'standard';
} & OuterProps;

export const Container: FC<ContainerProps> = (props) => {
  const { className, children, behavior = 'standard', ...otherProps } = props;
  return (
    <div
      className={twMerge(
        styles.Container,
        className,
        'mx-auto px-5',
        behavior === 'standard' && 'max-w-[1000px]',
        behavior === 'fill' && 'w-full',
      )}
      data-testid="Container"
      {...otherProps}
    >
      {children}
    </div>
  );
};
