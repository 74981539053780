import { useCallback } from 'react';

import type { ClientDTO, GetClientPathParams } from '../dice';
import {
  useDeleteClient,
  useGetClient,
  useGetClients,
  useSaveClients,
  useShareClient,
} from '../dice';

interface UseClientsProps {
  clientName?: GetClientPathParams['name'];
}

export const useClients = (props: UseClientsProps = {}) => {
  const { clientName } = props;

  const { data: clients, isPending: isPendingClients } = useGetClients(
    {},
    {
      enabled: Boolean(!clientName),
    },
  );

  const { data: client, isPending: isPendingClient } = useGetClient(
    {
      pathParams: {
        name: clientName!,
      },
    },
    {
      enabled: Boolean(clientName),
    },
  );

  const { mutateAsync: shareClientAsync } = useShareClient({});

  const { mutateAsync: deleteClientMutation } = useDeleteClient({});

  const { mutateAsync: updateOrCreateClient } = useSaveClients({});

  const getClient = useCallback(
    (id: string) =>
      Array.isArray(clients) ? clients.find((item) => String(item.id) === String(id)) : undefined,
    [clients],
  );

  const shareClient = useCallback(
    async (fromClientId: number, toClients: string[]) =>
      await shareClientAsync({
        body: toClients,
        queryParams: {
          clientId: fromClientId,
        },
      }),
    [shareClientAsync],
  );

  const createClient = useCallback(
    async (name: string) =>
      await updateOrCreateClient({
        body: [
          {
            clientName: name,
          },
        ],
      }),
    [updateOrCreateClient],
  );

  const deleteClient = useCallback(
    async (id: number) =>
      await deleteClientMutation({
        queryParams: {
          clientId: id,
        },
      }),
    [deleteClientMutation],
  );

  const updateClient = useCallback(
    async <TData = ClientDTO>(updatedClient: TData) =>
      await updateOrCreateClient({
        body: [updatedClient as ClientDTO],
      }),
    [updateOrCreateClient],
  );

  return {
    client,
    clients,
    createClient,
    deleteClient,
    getClient,
    isLoading: isPendingClient || isPendingClients,
    shareClient,
    updateClient,
  };
};
