import type { dice } from '@org/query';

export const WORKFLOW_STEP_STATUS = {
  CHANGED: 'CHANGED',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  EMPTY: 'EMPTY',
} as const;

type WorkflowStepStatus = (typeof WORKFLOW_STEP_STATUS)[keyof typeof WORKFLOW_STEP_STATUS];

export const getStatusColor = (status?: WorkflowStepStatus) => {
  switch (status) {
    case WORKFLOW_STEP_STATUS.ERROR: {
      return 'bg-error bg-red-500 text-white';
    }
    case WORKFLOW_STEP_STATUS.WARNING: {
      return 'bg-warning text-black';
    }
    case WORKFLOW_STEP_STATUS.SUCCESS: {
      return 'bg-success text-white ';
    }
    case WORKFLOW_STEP_STATUS.CHANGED: {
      return 'text-text';
    }
    default: {
      return '';
    }
  }
};

export const getConfigStatus = (
  configStatus?: 'FULLY_ALLOCATED' | 'PARTIALLY_ALLOCATED' | 'NOTHING_ALLOCATED',
) => {
  if (configStatus === 'FULLY_ALLOCATED') {
    return WORKFLOW_STEP_STATUS.SUCCESS;
  }

  if (configStatus === 'PARTIALLY_ALLOCATED') {
    return WORKFLOW_STEP_STATUS.WARNING;
  }

  if (configStatus === 'NOTHING_ALLOCATED') {
    return WORKFLOW_STEP_STATUS.ERROR;
  }
};

const EMPTY_STATUS_STATES = new Set(['PLANNED_COST_REVENUE']);

export const getStatusValue = ({
  configsStatus,
  name,
}: {
  configsStatus?: dice.ConfigsStatusDTO;
  name: dice.ConfigsStatusEntryDTO['configName'];
}) => {
  const statusEntry = configsStatus?.statusEntries?.find(({ configName }) => configName === name);

  if (name) {
    switch (statusEntry?.status) {
      case 'FULLY_CONFIGURED': {
        return WORKFLOW_STEP_STATUS.SUCCESS;
      }
      case 'PARTIALLY_CONFIGURED': {
        return WORKFLOW_STEP_STATUS.WARNING;
      }

      case 'NOTHING_CONFIGURED': {
        if (EMPTY_STATUS_STATES.has(name)) {
          return WORKFLOW_STEP_STATUS.EMPTY;
        } else {
          return WORKFLOW_STEP_STATUS.ERROR;
        }
      }
    }
  }
};
