'use client';

import type { FC } from 'react';
import Link from 'next/link';

import {
  ArrowRightStartOnRectangleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { useDisclosure } from '@nextui-org/react';
import { signOut } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { twMerge } from 'tailwind-merge';

import {
  Button,
  Container,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  PwcLogoDark,
} from '@org/ui';

import { HelpMenu } from '~/components';
import { LanguageSelect } from '../LanguageSelect';

type OuterProps = React.JSX.IntrinsicElements['header'];

export type HeaderProps = {} & OuterProps;

export const Header: FC<HeaderProps> = (props) => {
  const { className, children, ...otherProps } = props;
  const t = useTranslations();

  const { onOpenChange, isOpen, onOpen, onClose } = useDisclosure();

  return (
    <header
      className={twMerge(className, 'sticky top-0 bg-white shadow-md')}
      data-testid="Header"
      {...otherProps}
    >
      <Container
        behavior="fill"
        className="flex items-center justify-between"
      >
        <div className="flex h-[50px] items-center gap-2">
          <Link href="/">
            <PwcLogoDark
              className="cursor-pointer text-[40px]"
              name="PwcLogoDark"
            />
          </Link>
          <Divider
            className="!h-1/2"
            vertical
          />
          <span className="text-sm font-medium text-black">Gebühren- & Entgeltkalkulator</span>
          <Divider
            className="!h-1/2"
            vertical
          />
          <span className="text-sm text-gray-500">PwC ADI</span>
        </div>
        <div className="flex items-center gap-2">
          <LanguageSelect />

          <Button
            className="!size-8"
            isIconOnly
            onPress={onOpen}
            variant="light"
          >
            <QuestionMarkCircleIcon />
          </Button>

          <ArrowRightStartOnRectangleIcon
            className="size-8 cursor-pointer rounded p-1 hover:bg-gray-200"
            onClick={() => signOut()}
          />
        </div>
      </Container>

      <Modal
        isDismissable
        isOpen={isOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
        placement="top"
      >
        <ModalContent>
          <>
            <ModalHeader>{t('main.helpMenu.header')}</ModalHeader>
            <ModalBody>
              <HelpMenu />
            </ModalBody>
          </>
        </ModalContent>
      </Modal>

      {children}
    </header>
  );
};
