'use client';

import { useCallback } from 'react';

import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';

import { productIam } from '@org/query';
import { Button, Input, showNotification } from '@org/ui';

// TODO: Add translations

const messages = new Map([
  ['required', 'Email is required'],
  ['pattern', 'Please enter a valid email'],
]);

export function RegisterForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    values: {
      email: '',
    },
  });
  const { mutateAsync } = productIam.useInvite();
  const t = useTranslations();

  const onSubmit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        const { status, description } = await mutateAsync({
          body: {
            email,
          },
        });
        if (status === 'SUCCESS') {
          showNotification('success', description);
        } else {
          showNotification('error', description);
        }
      } catch {
        showNotification('error', t('common.alerts.error.general'));
      }
    },
    [mutateAsync, t],
  );

  return (
    <>
      <p className="text-2xl font-medium">Gebühren- & Entgeltkalkulator</p>
      <p>{t('common.landingPage.welcomeBack')}</p>
      <form
        className="flex flex-col"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="mb-3 mt-7 text-xs">{t('common.landingPage.email')}</p>

        <Input
          aria-label="Email"
          type="text"
          {...register('email', {
            required: true,
            pattern: /^\w.+@[\w-]+?(\.[A-Za-z]+)?\.[A-Za-z]{2,3}(.[A-Za-z]{2,3})?$/,
          })}
          aria-invalid={errors.email ? 'true' : 'false'}
        />
        <p className="text-xs text-red-light">{messages.get(errors.email?.type ?? '') ?? ''}</p>
        <Button
          className="mt-4"
          size="lg"
          type="submit"
          variant="secondary"
        >
          {t('common.landingPage.requestAccess')}
        </Button>
      </form>
    </>
  );
}
