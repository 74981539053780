import type { ICellRendererParams } from '@ag-grid-community/core';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { twMerge } from 'tailwind-merge';

import type { RowType } from '@org/utils';

import { Button } from '../Button';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ButtonCellRendererProps<T = any> = {
  icon?: ReactNode;
  text?: string;
  kind?: 'primary' | 'secondary' | 'transparent' | undefined;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  onClick: (event: unknown) => void;
  disabled: boolean;
  className?: string;
  gridProps: ICellRendererParams<T>;
  disableWrapper?: boolean;
} & ICellRendererParams<T>;

export interface ButtonHandlerProps extends ButtonCellRendererProps {
  type: RowType;
}

export const ButtonCellRendererMemo: FC<ButtonCellRendererProps> = (props) => {
  const {
    kind = 'cellRenderer',
    size = 'iconButton',
    onClick,
    disabled = false,
    icon,
    text,
    className,
    disableWrapper = false,
  } = props;

  const handleOnClick = () => {
    onClick(props);
  };
  if (disableWrapper) {
    return (
      <Button
        data-testid="ButtonRenderer"
        isDisabled={disabled}
        onPress={handleOnClick}
        size={size}
        startContent={icon}
        variant={kind}
      >
        {text}
      </Button>
    );
  }

  return (
    <div className={twMerge('flex h-full flex-row items-center justify-end', className)}>
      <Button
        data-testid="ButtonRenderer"
        isDisabled={disabled}
        onPress={handleOnClick}
        size={size}
        startContent={icon}
        variant={kind}
      >
        {text}
      </Button>
    </div>
  );
};

export const ButtonCellRenderer = memo(ButtonCellRendererMemo);
