'use client';

import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

import { useTranslations } from 'next-intl';

import { aggregated } from '@org/query';
import { Button, Container, showNotification } from '@org/ui';

import { usePathParams } from '~/hooks';
import { BAB_REPORT_PAGE } from '~/urls';

export function ConfigurationHeading() {
  const pathname = usePathname();
  const t = useTranslations();
  const pathParams = usePathParams();

  const router = useRouter();
  const config = pathname.split('/').at(-1);
  const { masterConfiguration } = aggregated.useMasterConfiguration(pathParams);

  const { recalculateReport, isRecalculating } = aggregated.useRecalculateReport(pathParams);

  const { selectedEntity } = aggregated.useMasterConfigurationByYearCalculation({
    ...pathParams,
    yearCalculationId: pathParams.yearId,
  });

  return (
    <Container behavior="fill">
      <div className="flex justify-between pb-2">
        <h4 className="text-lg font-medium">{t(`main.clients.menu.configMenu.${config}`)}</h4>
        <div className="flex gap-4">
          {selectedEntity?.reportId && (
            <Button
              as={Link}
              href={BAB_REPORT_PAGE(pathParams)}
            >
              {t('common.goToReport')}
            </Button>
          )}
          <Button
            isDisabled={isRecalculating}
            isLoading={isRecalculating}
            onClick={async () => {
              try {
                await recalculateReport({
                  masterConfiguration,
                });
                router.push(BAB_REPORT_PAGE(pathParams));
              } catch (error) {
                const errMessage = (error as Error).message;
                showNotification('warning', errMessage);
              }
            }}
          >
            {t('common.recalculate')}
          </Button>
        </div>
      </div>
      <hr />
    </Container>
  );
}
