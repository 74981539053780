import { DEFAULT_LOCALE } from './constants';

const DEFAULT_OPTIONS: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

export type FormatNumberToLocaleStringOptions = {
  defaultValue?: string;
  suffix?: string;
} & Intl.NumberFormatOptions;

export const formatNumberToLocaleString = (
  value: number | string | undefined | null,
  options: FormatNumberToLocaleStringOptions = {},
) => {
  const { defaultValue, suffix, ...otherProps } = options;
  if (value == null || (typeof value === 'string' && value.length === 0)) {
    return defaultValue ?? '';
  }
  const number = Number(value);
  if (Number.isNaN(number)) {
    return defaultValue ?? '';
  }

  const formattedNumber = number.toLocaleString(DEFAULT_LOCALE, {
    ...DEFAULT_OPTIONS,
    ...otherProps,
  });
  return `${formattedNumber}${suffix ?? ''}`;
};
