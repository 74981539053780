import type { QueryClientConfig } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import {
  defaultShouldDehydrateQuery,
  MutationCache,
  QueryClient,
  QueryClientProvider as RQProvider,
} from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import SuperJSON from 'superjson';

import type { dice } from '../queries';

export interface QueryProviderProps extends PropsWithChildren<{}> {
  queryClientConfig?: QueryClientConfig;
  showNotification: (type: 'error' | 'success', message: string) => void;
}

interface QueryError {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

export const QueryProvider: FC<QueryProviderProps> = (props) => {
  const { showNotification, ...rest } = props;
  const t = useTranslations();

  const {
    queryClientConfig = {
      defaultOptions: {
        queries: {
          retry(failureCount, error) {
            if ((error as unknown as QueryError).status >= 400) {
              return false;
            }
            if (failureCount < 2) {
              return true;
            }
            return false;
          },
          // 5 minutes
          staleTime: 300_000,
        },
        dehydrate: {
          serializeData: SuperJSON.serialize,
          shouldDehydrateQuery: (query) =>
            defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
        },
        hydrate: {
          deserializeData: SuperJSON.deserialize,
        },
      },
      mutationCache: new MutationCache({
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
        onError: ({ errorType }: dice.EsraException) => {
          if (errorType === 'MASTER_CONFIGURATION_FINALIZED') {
            return showNotification('error', t('common.finalizedConfigurationWarningDescription'));
          }
        },
      }),
    },
    ...queryProviderProps
  } = rest;
  /**
   * @see https://tanstack.com/query/v4/docs/guides/query-invalidation
   * @see https://tanstack.com/query/v4/docs/guides/prefetching
   * @see https://tanstack.com/query/v4/docs/guides/updates-from-mutation-responses
   * @example
   * // prefetching
   * const loader = async () => {
   *   const variables = { queryParams: { myQueryParam: 'something' } };
   *   await queryClient.prefetchQuery([queryKeyFn({
   *     path: '/ledgeraccounts/account',
   *     operationId: 'getAllLedgerAccountsForOneAccount',
   *     variables,
   *   })], fetchGetAllLedgerAccountsForOneAccount({ variables })
   *  );
   * };
   * @example
   * // Manually update query data
   * queryClient.setQueryData([queryKeyFn({
   *     path: '/ledgeraccounts/account',
   *     operationId: 'getAllLedgerAccountsForOneAccount',
   *     variables,
   *   })], { myData: 'data' });
   */
  const queryClient = useMemo(() => new QueryClient(queryClientConfig), [queryClientConfig]);

  return (
    <RQProvider
      {...queryProviderProps}
      client={queryClient}
    />
  );
};
